'use client'
import React, { Suspense, useState } from 'react';
import Button from '@/components/CommonComponent/button';
import styled from 'styled-components';
import Link from 'next/link';
import { motion, AnimatePresence } from "framer-motion";
import { FaMinus } from "react-icons/fa6";
import { GoPlus } from "react-icons/go";
const Index = ({ props }: any) => {
  // console.log('props of the growth section ', props)
  const [selectedServiceIndex, setSelectedServiceIndex] = useState(0);
  const handleServiceClick = (index: number) => {
    setSelectedServiceIndex(index);
  };
  return (
    <Suspense fallback={null}>
      <div className="bg-white xl:w-[95%] 2xl:w-[80%] mx-auto px-6 pt-12 lg:pt-16">
        <h2 className="text-homeblack text-center md:text-left font-bold text-3xl lg:text-4xl">
          {props?.title}
        </h2>
        <p className="text-homeblack mt-3" dangerouslySetInnerHTML={{ __html: props?.subtitle }} />
        {/* Mobile Accordion */}
        <div className="md:hidden my-8">
          {props?.card.map((elem: any, index: number) => (
            <div key={index} className="border-b border-gray-300">
              <button
                className={`w-full text-left bg-white p-4 ${selectedServiceIndex === index ? 'text-pink' : 'text-homeblack'
                  } text-lg font-semibold flex justify-between items-center transition-colors duration-300`}
                onClick={() => handleServiceClick(index)}
              >
                {elem?.service_name || elem?.title}
                <span className="transition-transform duration-300">
                  {selectedServiceIndex === index ? <FaMinus /> : <GoPlus />}
                </span>
              </button>
              <motion.div
                className="overflow-hidden bg-white"
                initial={false}
                animate={selectedServiceIndex === index ? { height: 'auto', opacity: 1, scale: 1 } : { height: 0, opacity: 0, scale: 0.9 }}
                exit={{ height: 0, opacity: 0, scale: 0.95 }}
                transition={{ duration: 0.4, ease: 'easeInOut' }}
              >
                <div className="p-4">
                  {/* <div className="flex  font-semibold text-homeblack text-[28px] bg-[url('/images/priya/technique2_banner.png')] min-h-[219px] rounded-lg bg-cover bg-no-repeat justify-start p-5 items-center mb-6">
                  <h3>{props?.card[selectedServiceIndex]?.title}</h3>
                </div> */}
                  <motion.div
                    className="flex flex-col text-homeblack  font-semibold text-[28px]  rounded-lg justify-start  items-center mb-6 relative"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3, ease: "easeOut" }}
                  >
                    {/* Foreground Image */}
                    <img
                      src={props?.card[selectedServiceIndex]?.icon || "/images/priya/technique2_banner.webp"}
                      alt="Technique Banner"
                      className="w-full  h-auto rounded-lg"
                    />
                    {/* Text Overlay */}
                    <p className="absolute top-1/2 left-[40%] sm:left-1/4 text-start  transform -translate-x-1/2 -translate-y-1/2 text-blue">
                      {props?.card[selectedServiceIndex]?.title}
                    </p>
                  </motion.div>
                  <StyledWrapper>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: elem?.description || 'No description available.',
                      }}
                    />
                  </StyledWrapper>
                </div>
              </motion.div>
            </div>
          ))}
        </div>
        <div className="hidden md:flex my-10 items-start justify-between gap-6">
          {/* Sidebar Tabs */}
          <div className="flex flex-col gap-3 md:w-[320px] lg:w-[300px]">
            {props?.card.map((elem: any, index: any) => (
              <motion.div
                key={index}
                onClick={() => handleServiceClick(index)}
                className={`w-full text-center text-homeblack py-4 px-4 rounded-xl cursor-pointer transition-all duration-150 ease-in-out hover:shadow-lg 
                ${selectedServiceIndex === index ? "text-pink border-[1px] border-lightblue shadow-lg bg-lightblue/10" : "bg-white"
                  }`}
                initial={{ opacity: 0, }}
                whileInView={{ opacity: 1, }}
                transition={{ duration: 0.3, ease: "easeInOut", delay: index * 0.1 }}
              >
                <p className="!font-semibold text-[16px]">{elem?.service_name || elem?.title}</p>
              </motion.div>
            ))}
          </div>
          {/* Content Panel */}
          <AnimatePresence mode="wait">
            {selectedServiceIndex !== -1 && (
              <motion.div
                key={selectedServiceIndex}
                className="bg-white w-full px-4 py-6 md:p-8 rounded-lg shadow-md"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                {/* Banner with Image & Title */}
                <motion.div
                  className="flex flex-col text-homeblack  font-semibold text-[28px]  rounded-lg justify-start  items-center mb-6 relative"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3, ease: "easeOut" }}
                >
                  {/* Foreground Image */}
                  <img
                    src={props?.card[selectedServiceIndex]?.icon || "/images/priya/technique2_banner.webp"}
                    alt="Technique Banner"
                    className="w-full h-auto rounded-lg" />
                  {/* Text Overlay */}
                  <p className="absolute top-1/2 left-1/4 text-start  transform -translate-x-1/2 -translate-y-1/2 text-blue">
                    {props?.card[selectedServiceIndex]?.title}
                  </p>
                </motion.div>
                {/* Content Text with Staggered Fade-in */}
                <StyledWrapper>
                  <motion.div
                    className="text-homegrey"
                    initial={{ opacity: 0, y: 5 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 5 }}
                    transition={{ duration: 0.3, ease: "easeInOut", delay: 0.1 }}
                    dangerouslySetInnerHTML={{
                      __html: props?.card[selectedServiceIndex]?.description || "No description available.",
                    }}
                  />  
                  </StyledWrapper>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {/* Call to Action Button */}
        <div className="flex justify-center mt-8">
          <Link href="/our-client-list">
            <Button content="Join Our Growing List of Partners" />
          </Link>
        </div>
      </div></Suspense>
  );
};

export default Index;

export const StyledWrapper = styled.div`
  ul {
    list-style: disc;
  }
  h1 {
    font-size: 32px !important;
    font-weight: 700;
    color: var(--homeblack) !important;
  }
  h2 {
    font-size: 26px !important;
  }
  h3 {
    font-size: 22px !important;
    font-weight: 500 !important;
  }
  p {
    font-size: 17px;
    font-weight: 400;
    color: #535353;
  }
  ol,
  ul {
    padding-left: 1.5rem;
    list-style-type: disc;
    color: #535353;
    font-size: 17px !important;
  }
  ul li::marker {
    color: #1090d0;
    font-size: 23px;
  }
  table {
    margin-top: 26px;
    margin-bottom: 26px;
    color: #535353;
    width: 100%;
  }
  td {
    padding: 15px;
    color: #535353;
  }
  a {
    color: #ef1649;
    font-weight: 500;
  }
`;
